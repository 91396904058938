import React from 'react';
import './CSS/CARD.css';
// import Fire from './assets/FireTransparent.png';
import Wood from './assets/WaterTransparent.png';
function CARD() {
    return (
        <div className="CARD">
            <div className="outSide">
                <div className="makeACard">
                </div>
            </div>
        </div>
    )
}

export default CARD
